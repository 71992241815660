/*
  =============== 
  Navbar
  ===============
  */
.nav1 {
  background: var(--clr-white);
  box-shadow: var(--light-shadow);
  grid-area: navbar;
  position: sticky;
  top: 0;
  background-color: rgb(65, 65, 65);
}

.nav1 .nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0px 0px 20px;
  height: 40px;
}
.nav1 .nav-toggle {
  font-size: 1.5rem;
  color: white;
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav1 .nav-toggle:hover {
  transform: rotate(90deg);
}
.nav1 .logo {
  height: 40px;
}
.nav1 .links a {
  color: rgb(220, 220, 220);
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  border-top: solid 1px;
}
.nav1 .links a :after {
  border-top: 1px solid white;
}
.nav1 .links a:hover {
  background: white;
  color: rgb(45, 45, 45);
  position: flex;
}
.nav1 .social-icons {
  display: none;
}
.nav1 .links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.nav1 .show-container {
  height: 10rem;
}

@media screen and (min-width: 800px) {
  .nav1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "hello";
    background: rgb(45, 45, 45);
  }
  .nav1 .links-container .links {
    grid-area: hello;
    list-style-type: none;
    display: grid;
    background-color: rgb(65, 65, 65);
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .nav1 .social-icons li {
    border-right: none;
    border-width: 1px;
    height: 40px;
  }

  .nav1 li {
    border-right: solid;
    border-width: 1px;
    height: 40px;
    border-color: grey;
  }
  .nav1 .nav-header {
    padding: 0;
    display: none;
  }
  .nav1 .nav-toggle {
    display: none;
  }
  .nav1 .links-container {
    height: auto !important;
  }

  .nav1 .links a {
    border-top: none;
    color: white;
    text-align: center;
  }
  .nav1 .links a:hover {
    background: transparent;
    color: grey;
  }
  .nav1 .social-icons {
    display: flex;
  }
  .nav1 .social-icons a {
    color: white;
    transition: var(--transition);
  }
  .nav1 .social-icons a:hover {
    color: var(--clr-primary-7);
  }
}
