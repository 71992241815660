.nav2 {
  grid-area: leftsidebar;
  display: grid;
  background-color: rgb(100, 100, 100);
}

.nav2 a {
  color: black;
  font-weight: 700;
}

.nav2 li {
  display: grid;

  text-align: center;
  border: solid;
  border-width: 1px;
  border-top: none;
  padding: 0.2rem;
  border-color: white;
  background-color: rgb(200, 200, 200);
}
.nav2 .header1 {
  border-top: solid white;
  border-width: 1px;
  background-color: rgb(50, 50, 50);
  font-weight: 700;
  color: white;
}
.nav2 .header2 {
  background-color: rgb(50, 50, 50);
  font-weight: 700;
  color: white;
}
