.tierlist {
}
.tierlist .tier-wrapper {
  border: solid black;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 10fr;
  padding-top: 0;
}
#SS {
  background-color: rgb(250, 50, 50);
}
#S {
  background-color: rgb(250, 150, 100);
}
#A {
  background-color: rgb(250, 250, 150);
}
#B {
  background-color: rgb(125, 200, 75);
}

#C {
  background-color: rgb(0, 150, 0);
}

.tierlist .gap1 {
  content: "";
  height: 5px;
}
.tierlist .tier-wrapper .tier {
  width: 50px;
  margin: auto;
  text-align: center;
}

.tierlist li {
  padding: 6px 6px;
  display: grid;
}
.tierlist ul {
  border-radius: 0 8px 8px 0;
  border-left: solid;
  background-color: rgb(250, 250, 250);
}
.tierlist a img {
  display: block;

  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.tierlist h5 {
  text-align: center;
}
@media screen and (max-width: 294px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px;
    grid-gap: 15px;
  }
}

@media screen and (min-width: 295px) and (max-width: 370px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 371px) and (max-width: 400px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 401px) and (max-width: 487px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 488px) and (max-width: 600px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 601px) and (max-width: 679px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 680px) and (max-width: 783px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 784px) and (max-width: 800px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 801px) and (max-width: 880px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 881px) and (max-width: 1010px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1011px) {
  .tierlist ul {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px;
    grid-gap: 15px;
  }
}
