/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1080px;
  --fixed-width: 620px;
}
/*
  =============== 
  Global Styles
  ===============
  */

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.section {
  display: grid;
  grid-template-columns: 2fr 7fr 3fr;

  grid-template-areas:
    "cover cover cover"
    "navbar navbar navbar"
    "topads topads topads"
    "leftsidebar main rightsidebar"
    "footer footer footer";
  grid-gap: 0.2rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: rgb(250, 250, 250);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

/*  global classes */

/* section */
.section {
  background: rgb(250, 250, 250);

  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (max-width: 800px) {
  .section {
    display: grid;
    grid-template-columns: 3fr 9fr;

    grid-template-areas:
      "cover cover"
      "navbar navbar"
      "topads topads"
      "leftsidebar main"
      "rightsidebar main"
      "footer footer";

    grid-gap: 0.2rem;
  }
}
@media screen and (max-width: 400px) {
  .section {
    display: grid;
    grid-template-columns: 1fr;

    grid-template-areas:
      "cover"
      "navbar"
      "topads"
      "leftsidebar"
      "main"
      "rightsidebar"
      "footer";
    grid-gap: 0.2rem;
  }
}

@media screen and (min-width: 1080px) {
  .section {
    width: 1080px;
  }
}
