#cover {
  background-image: url(genshin_cover.png);

  background-size: cover;

  grid-area: cover;
  color: #ffffff;
  height: 150px;
  position: relative;
}
#cover h1 {
  font-size: 20px;
  position: absolute;
  bottom: -10px;

  left: 10px;
  text-shadow: 2px 2px black;
  color: rgb(255, 255, 255);
  background-color: transparent;
}
